<template>
  <div class="app-container">
    <!-- 顶部导航栏 -->
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="欠费公司" name="second"></el-tab-pane>
      </el-tabs>
    </div>

    <!-- 筛选 -->
    <div class="top_btns clearfix">
      <div class="left_search fl">
        <el-input placeholder="请输入公司名称或联系人" v-model="listQuery.name" style="width: 200px;margin-right:10px" size="small" />
        <el-button class="filter-item" type="primary" @click="getList" size="small" plain>搜索</el-button>
        <el-select size="small" v-model="listQuery.status" @change="getList" filterable placeholder="请选择">
          <el-option key="0" label="全部" :value="0"></el-option>
          <el-option key="1" label="正常使用" :value="1"></el-option>
          <el-option key="2" label="试用" :value="2"></el-option>
          <el-option key="3" label="停用" :value="3"></el-option>
          <el-option key="4" label="活动账号" :value="4"></el-option>

        </el-select>
      </div>
      <div class="right_btns fr">
        <el-button @click="migration()" type="success" size="small">迁移</el-button>
        <el-button @click="handleCreate()" type="primary" size="small">新增</el-button>
        <el-button @click="daochu()" type="primary" size="small">导出</el-button>
        <!-- <el-button @click="handleApply()" type="success" size="small" plain>试用列表</el-button> -->
      </div>
    </div>

    <!-- table -->
    <el-table :height="contentStyleObj" :data="list" border fit highlight-current-row style="width: 100%;" @selection-change="handleSelectionChange" @sort-change="sortChange" v-loading="loading">
      <!-- <el-table-column type="selection" align="center"/> -->
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="序号" align="center" width="55" type="index">
      </el-table-column>

      <!-- <el-table-column label="时间" align="center" min-width="100">
        <template #default="scope">
          <span>{{  $parseTime(scope.row.createdAt, "{y}-{m}-{d}") }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="集团名称" align="center" width="280">
        <template #default="scope">
          <!-- <el-input v-model="scope.row.groupName" style="width:95%"/> -->
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="集团账号管理" align="center" width="260">
        <template #default="scope">
          <el-button size="small" type="success" @click="handleGroup(scope.row)" plain>集团账号管理</el-button>
          <el-button size="small" type="success" @click="handlemod(scope.row)" plain>修改</el-button>
          <el-button size="small" type="danger" @click="handledel(scope.row)" plain>删除</el-button>
        </template>
      </el-table-column>
     <el-table-column label="公司名" align="center" width="280">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.name}}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center" width="100">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.manager}}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="电话" align="center" width="110">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.managerTel}}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="创建人" align="center" width="100">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.createName}}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="100">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <!-- <div v-if="item.isTrial != 1">
              <span v-if="item.status == 1" style="color:green">正常使用</span>
              <span v-else style="color:red">已停用</span>
            </div>
            <div v-else>
              <span v-if="item.status == 1" style="color:blue">试用</span>
              <span v-else style="color:red">已停用</span>
            </div> -->

            <div v-if="item.isTrial == 0">
              <span v-if="item.status == 1" style="color:green">正常使用</span>
              <span v-else style="color:red">已停用</span>
            </div>
            <div v-if="item.isTrial == 1">
              <span v-if="item.status == 1" style="color:blue">试用</span>
              <span v-else style="color:red">已停用</span>
            </div>
            <div v-if="item.isTrial == 2">
              <span v-if="item.status == 1" style="color:orange">活动</span>
              <span v-else style="color:red">已停用</span>
            </div>
          </p>
        </template>
      </el-table-column>

      <el-table-column label="测试企业" align="center" min-width="90">
          <template #default="scope">
            <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
              <div >
                <span v-if="item.isCs == 1" style="color:red">是</span>
                <span v-else style="color:green">否</span>
              </div>
            </p>
          </template>
        </el-table-column>

      <el-table-column label="状态" align="center" width="100">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span v-if="item.simple != 1" style="color:green">正常版</span>
            <span v-else style="color:blue">简易版</span>
          </p>
          
        </template>
      </el-table-column>

      <el-table-column label="账套个数" align="center" width="140">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.count}}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="已使用" align="center" width="75">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.used}}</span>
          </p>
        </template>
      </el-table-column>

      <el-table-column label="账套启用日期" align="center" width="120">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span v-if="!item.countTime.match('0001')">{{$parseTime(item.countTime, "{y}-{m}-{d}")}}</span>
            <span v-else>暂未设置</span>
          </p>
        </template>
      </el-table-column>

      <el-table-column label="账套到期日期" align="center" width="120">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.countUseTime}}</span>
          </p>
        </template>
      </el-table-column>

      <el-table-column label="所属税务总局" align="center" width="100">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.districtName}}</span>
          </p>
        </template>
      </el-table-column>

      <el-table-column label="备注" align="center" width="100">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <span>{{item.remark}}</span>
          </p>
        </template>
      </el-table-column>

      <el-table-column label="logo" align="center" width="120">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <img :src="item.logoUrl" alt="" srcset="" style="width:100px;">
          </p>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="380" class-name="small-padding fixed-width">
        <template #default="scope">
          <p class="item-p" v-for="(item, index) in scope.row.item" :key="index">
            <el-button type="primary" size="small" @click="handleUpdate(item)" plain>编辑</el-button>
            <el-button size="small" type="success" @click="handleAdmin(item)" plain>管理员账号</el-button>
            <el-button size="small" type="danger" @click="handleDelete(item)" plain>删除</el-button>
            <el-button type="danger" v-if="item.status == 1" size="small" class="tablebutton" @click="stopStatus(item)" plain>停用</el-button>
            <el-button type="success" v-else size="small" class="tablebutton" @click="startStatus(item)" plain>启用</el-button>
            <el-button size="small" type="success" @click="settingCount(item)" plain>设置</el-button>
            <!-- <el-button size="small" type="primary" @click="settingSupplier(item)" plain>设置为供应商</el-button> -->
          </p>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

<!-- 新增编辑的弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible"  class="button_bg" width="700px">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="140px" >
        <div class="grid-content bg-purple">
          <el-form-item label="集团名称:" prop="name" size="small">
            <group v-model:groupId="temp.groupId"></group>
          </el-form-item>
          <el-form-item label="公司名称:" prop="name" size="small">
            <el-input v-model="temp.name" style="width:95%"/>
          </el-form-item>
          <el-form-item label="负责人:" prop="manager" size="small">
            <el-input v-model="temp.manager" style="width:95%"/>
          </el-form-item>

          <el-form-item label="负责人手机号:" prop="managerTel" size="small">
            <el-input v-model="temp.managerTel" style="width:95%"/>
          </el-form-item>

          <el-form-item label="地址:" prop="address" size="small">
            <el-input v-model="temp.address" style="width:95%"/>
          </el-form-item>

          <el-form-item label="用户类型:" prop="isTrial">
            <el-radio-group v-model="temp.isTrial">
              <el-radio :label="0">正常</el-radio>
              <el-radio :label="1">试用</el-radio>
              <el-radio :label="2">活动账号</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="测试企业:" prop="simple">
            <el-radio-group v-model="temp.isCs">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- <el-form-item label="共享机器人:" prop="robot">
            <el-radio-group v-model="temp.robot">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="简易版:" prop="simple">
            <el-radio-group v-model="temp.simple">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="所属税务总局:" prop="districtCode" size="small">
            <selectcity v-model:citys="temp.districtCode"></selectcity>
          </el-form-item>

          <el-form-item label="备注:" prop="remark" size="small">
            <el-input v-model="temp.remark" style="width:95%" type="textarea"/>
          </el-form-item>
          <el-form-item label="上传logo:">
            <upload @uploadimgzj="imgUrl" :img2="this.temp.logoUrl"/>
          </el-form-item>
            
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</el-button>
      </span>
    </template>
    </el-dialog>
<!-- 迁移弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="迁移" v-model="dialogFormVisible1" class="button_bg" width="1200px">
      <el-input placeholder="请输入公司名称或联系人" v-model="listQuery1.name" style="width: 200px;margin-right:5px;margin-bottom: 5px;" size="small" />
      <el-button class="filter-item" type="primary" @click="migration" size="small" plain>搜索</el-button>
      <el-table :height="contentStyleObj1" :data="list1" border fit highlight-current-row style="width: 100%;" @selection-change="handleSelectionChange" @sort-change="sortChange" size="small">
        <el-table-column label="序号" align="center" width="55" type="index">
        </el-table-column>
      <el-table-column label="公司名" align="center" min-width="200">
          <template #default="scope">
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center" min-width="100">
          <template #default="scope">
            <span>{{scope.row.manager}}</span>
          </template>
        </el-table-column>
        <el-table-column label="电话" align="center" min-width="110">
          <template #default="scope">
            <span>{{scope.row.managerTel}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" min-width="90">
          <template #default="scope">
            <div v-if="scope.row.isTrial == 0">
              <span v-if="scope.row.status == 1" style="color:green">正常使用</span>
              <span v-else style="color:red">已停用</span>
            </div>
            <div v-if="scope.row.isTrial == 1">
              <span v-if="scope.row.status == 1" style="color:blue">试用</span>
              <span v-else style="color:red">已停用</span>
            </div>
            <div v-if="scope.row.isTrial == 2">
              <span v-if="scope.row.status == 1" style="color:orange">活动</span>
              <span v-else style="color:red">已停用</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="测试企业" align="center" min-width="90">
          <template #default="scope">
            <span v-if="scope.row.isCs == 1" style="color:red">是</span>
            <span v-else style="color:green">否</span>
          </template>
        </el-table-column>

        <el-table-column label="状态" align="center" min-width="90">
          <template #default="scope">
            <span v-if="scope.row.simple != 1" style="color:green">正常版</span>
            <span v-else style="color:blue">简易版</span>
          </template>
        </el-table-column>

        <el-table-column label="账套个数" align="center" min-width="75">
          <template #default="scope">
            <span>{{scope.row.count}}</span>
          </template>
        </el-table-column>

        <el-table-column label="账套启用日期" align="center" min-width="100">
          <template #default="scope">
            <span v-if="!scope.row.countTime.match('0001')">{{$parseTime(scope.row.countTime, "{y}-{m}-{d}")}}</span>
            <span v-else>暂未设置</span>
          </template>
        </el-table-column>

        <el-table-column label="账套到期日期" align="center" min-width="100">
          <template #default="scope">
            <span>{{scope.row.countUseTime}}</span>
          </template>
        </el-table-column>

        <el-table-column label="备注" align="center" min-width="100">
          <template #default="scope">
            <span>{{scope.row.remark}}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" min-width="100">
          <template #default="scope">
          <el-button type="primary" size="small" @click="qianyi(scope.row)" plain>迁移</el-button>
        </template>
        </el-table-column>
      </el-table>
      <div class="pagination" style="margin-top:0px">
        <qzf-pagination v-show="total1>0" :total="total1" v-model:page="listQuery1.page" v-model:limit="listQuery1.limit" @pagination="migration" />
      </div>
    </el-dialog>
<!-- 设置管理员弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="设置管理员账号" v-model="dialogAdminFormVisible"  class="button_bg" width="500px">
      <el-form :model="adminTemp" label-position="right" label-width="100px" >
          <el-form-item label="姓名:" prop="name" size="small">
            <el-input v-model="adminTemp.cnName" style="width:85%"/>
          </el-form-item>

          <el-form-item label="手机号:" prop="manager" size="small">
            <el-input disabled v-model="adminTemp.mobile" style="width:85%"/>
          </el-form-item>

          <el-form-item label="用户名:" prop="managerTel" size="small">
            <el-input v-model="adminTemp.userName" style="width:85%"/>
          </el-form-item>

          <el-form-item label="密码:" prop="address" size="small">
            <el-input disabled v-model="adminTemp.password" style="width:85%"/>
          </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogAdminFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="adminUpdate" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>
<!-- 设置集团账号管理 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="设置集团账号管理" v-model="dialogAdminFormVisible1"  class="button_bg" width="500px">
      <el-form :model="adminGly" label-position="right" label-width="100px" >
          <el-form-item label="姓名:" prop="name" size="small">
            <el-input v-model="adminGly.cnName" style="width:85%"/>
          </el-form-item>
          <el-form-item label="用户名:" prop="managerTel" size="small">
            <el-input v-model="adminGly.userName" style="width:85%"/>
          </el-form-item>
          <el-form-item label="密码:" prop="address" size="small">
            <el-input v-model="adminGly.password" style="width:85%"/>
          </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogAdminFormVisible1 = false" size="small">取消</el-button>
          <el-button type="primary" @click="adminUpdateGLY" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="修改集团名称" v-model="dialogAdminFormVisible2"  class="button_bg" width="500px">
      <el-form :model="adminXG" label-position="right" label-width="100px" >
          <el-form-item label="集团名称:" prop="name" size="small">
            <el-input v-model="adminXG.name" style="width:85%"/>
          </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogAdminFormVisible2 = false" size="small">取消</el-button>
          <el-button type="primary" @click="adminUpdateXG" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>
<!-- 设置账套个数 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="设置账套" v-model="dialogCountFormVisible"  class="button_bg" width="400px">
      <el-form :model="countsTemp" label-position="right" label-width="100px" >
        <el-form-item label="账套个数:" prop="count">
          <el-input style="width:220px"  type="number" v-model.number="countsTemp.count" size="small"/>
        </el-form-item>
        <el-form-item label="启用时间:" prop="countTime" style="width:98%;">
          <el-date-picker v-model="countsTemp.countTime" type="date" placeholder="选择日期" @change="QYSJ" size="small">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="到期时间:" style="width:98%;">
          <el-date-picker v-model="countsTemp.countUseTime" value-format="YYYY-MM-DD" type="date" placeholder="选择日期" size="small">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogCountFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="updataCount" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import selectcity from "@/components/Screening/selectcity";
import group from "./components/group.vue"
import { oldOrgList,moveOrg } from "@/api/move"
import upload from "@/components/uploadImg/upload";

import { orgList , saveOrg , delOrg , findOrgAdmin , saveOrgAdmin,getGroupAdmin,saveGroupAdmin,delGroup,saveGroup } from "@/api/org"
export default {
  name:'org-manage',
  components:{
    selectcity,
    group,
    upload
  },
  data() {
    return {
      activeName:'first',
      listQuery: {
        page: 1,
        limit: 20,
        name:'',
        credits: 0,
        status: 0,
      },
      //迁移
      listQuery1: {
        page: 1,
        limit: 20,
        name:'',
      },
      total: 0,
      total1: 1,
      list:[],
      list1: [],
      loading:false,  //表格加载
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogAdminFormVisible: false,
      dialogAdminFormVisible1: false,
      dialogAdminFormVisible2: false,
      dialogCountFormVisible:false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增'
      },
      temp: {
        managerTel: '',
        name: '',
        manager: '',
        districtCode: '',
        address: '',
        logoUrl: '',
        groupId: 0
      },
      adminTemp: {
        cnName:"",
        userName: '',
        mobile:'',
        password: '',
      },
      adminGly: {
        cnName:"",
        userName: '',
        password: '',
        groupId: ''
      },
      adminXG: {
        id: 0,
        name:"",
      },
      countsTemp: {
        count: 0,
        countTime: "",
        countUseTime:'',
      },
      rules: {
        manager: [{ required: true, message: '请输入负责人', trigger: 'change' }],
        managerTel: [{ required: true, message: '请输入负责人电话', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        districtCode: [{ required: true, message: '请选择服务地址', trigger: 'blur' }]
      },
      orgId:''
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(290)
    this.contentStyleObj1= this.$getHeight(400)
    this.getList()
  },
  methods:{
    QYSJ(val) {
      console.log(val,'val')
    },
    getList(){
      this.loading = true
      orgList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    //迁移
    migration() {
      this.dialogFormVisible1 = true
      oldOrgList(this.listQuery1).then(res => {
        if(res.data.msg == 'success') {
          this.list1 = res.data.data.list
          this.total1 = res.data.data.total
        }
      })
    },
    resetTemp() {
      this.temp = {
        managerTel: '',
        name: '',
        manager: '',
        districtCode: [],
        address: '',
        type:'agent',
        logoUrl: ''
      }
    },
    handleClick(tab){
      if(tab.props.name == "first") {
        this.listQuery.credits = 0
        this.getList()
      }if(tab.props.name == "second") {
        this.listQuery.credits = 1
        this.getList()
      }
    },
    createData() {
      // this.$refs['dataForm'].validate((valid) => {
      //   console.log(valid);
      //   if (valid) {
          saveOrg(this.temp).then(res => {
            if(res.data.msg == 'success'){
              this.getList()
              this.dialogFormVisible = false
              this.$qzfMessage("创建成功")
            } 
          })
        // }
      // })
    },
    updateData() {
      // this.$refs['dataForm'].validate((valid) => {
        // if (valid) {
          saveOrg(this.temp).then(res => {
            if(res.data.msg == 'success'){
              this.getList()
              this.dialogFormVisible = false
              this.$qzfMessage("更新成功")
            }
          })
        // }
      // })
    },
    handleDelete(row){
      this.$confirm('你确定要删除该代账公司吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOrg(row).then(res=>{
          if(res.data.msg == 'success'){
            this.getList()
            this.$qzfMessage("删除成功")
          }
        })
      });
    },
    handleAdmin(row){
      findOrgAdmin({orgId:row.id}).then(res=>{
        if(res.data.msg == "success"){
          this.adminTemp = res.data.data.data
          this.adminTemp.orgId = row.id
          this.adminTemp.isAdmin = 1
        }
      })
      this.dialogAdminFormVisible = true
    },
    //集团账号管理
    handleGroup(row){
      getGroupAdmin({groupId:row.groupId}).then(res=>{
        if(res.data.msg == "success"){
          this.adminGly = res.data.data.info
          this.adminGly.groupId = row.groupId
        }
      })
      this.dialogAdminFormVisible1 = true
    },
    //修改
    handlemod(row) {
      this.dialogAdminFormVisible2 = true
      this.adminXG.name = row.name
      this.adminXG.id = row.groupId
    },
    //修改确认
    adminUpdateXG() {
      saveGroup(this.adminXG).then(res => {
        if(res.data.msg == 'success'){
          this.getList()
          this.$qzfMessage("修改成功")
          this.dialogAdminFormVisible2 = false
        }
      })
    },
    //删除
    handledel(row) {
      this.$confirm('你确定要删除该集团账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delGroup({id:row.groupId}).then(res=>{
          if(res.data.msg == 'success'){
            this.getList()
            this.$qzfMessage("删除成功")
          }
        })
      });
    },
    qianyi(row) {
      this.$confirm('你确定要迁移该账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        moveOrg({id:row.id}).then(res=>{
          if(res.data.msg == 'success'){
            this.migration()
            this.$qzfMessage("迁移成功")
          }
        })
      });
    },
    stopStatus(row){
      row.status = 3
      saveOrg(row).then(res => {
        if(res.data.msg == 'success'){
          this.getList()
          this.$qzfMessage("停用成功")
        }
      })
    },
    startStatus(row){
      row.status = 1
      saveOrg(row).then(res => {
        if(res.data.msg == 'success'){
          this.getList()
          this.$qzfMessage("启用成功")
        }
      })
    },
    adminUpdate(){
      saveOrgAdmin(this.adminTemp).then(res=>{
        if(res.data.msg == 'success'){
          this.dialogAdminFormVisible = false
          this.getList()
          this.$qzfMessage("操作成功")
        }
      })
    },
    //集团账号确认
    adminUpdateGLY() {
      saveGroupAdmin(this.adminGly).then(res=>{
        if(res.data.msg == 'success'){
          this.dialogAdminFormVisible1 = false
          this.getList()
          this.$qzfMessage("操作成功")
        }
      })
    },
    settingCount(row){
      //防止热更新(复制一份再修改)
      let copy = Object.assign({},row)
      this.countsTemp = copy
      this.dialogCountFormVisible = true
      this.countsTemp.id = copy.id
      if(row.countTime.match('0001')) {
        this.countsTemp.countTime = ''
      }
    },
    updataCount(){
      if(!this.countsTemp.count){
        this.$qzfMessage('账套个数不能为0',2);
        return
      }
      if(!this.countsTemp.countTime){
        this.$qzfMessage('请选择启用时间',2);
        return
      }
      let param = {
        id: this.countsTemp.id,
        count: this.countsTemp.count,
        countTime: this.countsTemp.countTime,
        countUseTime: this.countsTemp.countUseTime,
        isTrial:this.countsTemp.isTrial,
        isCs:this.countsTemp.isCs
      }
      saveOrg(param).then(res => {
        if(res.data.msg == "success"){
          this.dialogCountFormVisible = false
          this.getList()
          this.$qzfMessage('修改成功');
        }
      })
    },
    imgUrl(val) {
      this.temp.logoUrl = val
    },
  }
}
</script>

<style scoped lang="scss">
.top_btns {
  margin-bottom: 10px;
  .left_search{
    float: left;
    width: 70%;
  }
  .right_btns {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.el-input {
  width: 96%;
}
.item-p{
  border-top: 1px solid #efefef;
}
.item-p:nth-child(1){
  border-top: none;
}
</style>