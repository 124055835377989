import request from '@/utils/request'
//迁移列表
export function oldOrgList(data) {
  return request({
    url: '/api/v2/move/oldOrgList',
    method: 'post',
    data
  })
}

//迁移
export function moveOrg(data) {
  return request({
    url: '/api/v2/move/moveOrg',
    method: 'post',
    data
  })
}
//采集
export function companyMove(data) {
  return request({
    url: '/api/v2/move/companyMove',
    method: 'post',
    data
  })
}

//历史数据迁移
export function companyOldMove(data) {
  return request({
    url: '/api/v2/move/companyOldMove',
    method: 'post',
    data
  })
}

//学生教学包列表
export function getPkgInfoList(data) {
  return request({
    url: '/api/v2/move/getPkgInfoList',
    method: 'post',
    data
  })
}

// 学生领取教学包
export function getCompanyInfoPkg(data) {
  return request({
    url: '/api/v2/move/getCompanyInfoPkg',
    method: 'post',
    data
  })
}