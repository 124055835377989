<template>
  <el-select placeholder="请选择" v-model="id" size="small" filterable>
    <el-option style="color:red" label="+新增" value="add"></el-option>
    <el-option v-for="(item,index) in list " :key="index" :label="item.name" :value="item.id"></el-option>
  </el-select>

  <el-dialog :close-on-click-modal="false" destroy-on-close append-to-body title="新增科目" v-model="dialogVisible" width="25%" >
    <el-form :model="temp" label-width="100px" label-position="right" size="small">
      <el-form-item label="集团名称：">
        <el-input v-model="temp.name"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="save" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
// <group v-model:groupId="groupId"></group>
import { groupList, saveGroup } from "@/api/org"
export default {
  props: {
    groupId: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      dialogVisible: false,
      temp: {
        name: ""
      },
    }
  },
  computed: {
    id: {
      get() {
        if(!this.groupId){
          return ""
        }
        return this.groupId
      },
      set(val) {
        if(val == 'add'){
          this.dialogVisible = true
          return
        }
        this.$emit("update:groupId", val * 1)
        this.$emit("success", val * 1)
      }
      
    }
  },
  created () {
    this.init()
  },
  methods: {
    save(){
      saveGroup(this.temp).then(res=>{
        if(res.data.msg == "success"){
          this.dialogVisible = false
          this.init(res.data.data.id)
          
        }
      })
    },
    init(id){
      groupList({}).then(res=>{
        this.list = res.data.data.list
        if(id){
          this.id = id
        }
      })
    },
  }
}
</script>